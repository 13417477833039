import md5 from 'md5';
import { useCookies } from 'react-cookie';

const CONSENT_COOKIE_NAME = 'dbconsent';
const COOKIE_LONG_LIVE_DAYS = 360;
const COOKIE_SHORT_LIVE_DAYS = 90;
const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export enum ConsentLevel {
  Necessary = 0,
  Functional = 1,
  Statistics = 2,
  Marketing = 3,
}

export enum ConsentType {
  Passive = 0,
  Active = 1,
}

type ConsentVersion = {
  layout?: string;
  content?: string;
};

type ConsentData = {
  level: ConsentLevel;
  type: ConsentType;
  time: number;
  version: ConsentVersion;
  id: string;
  info: string;
};

export type useCookieConsentConfig = {
  levelDefault?: ConsentLevel;
  levelMax?: ConsentLevel;
  logApiEndpoint?: string | null;
};

function getDomain() {
  let domain = '';
  if (document.location.host.search(/^localhost:\d{4}$/) === 0) {
    return '';
  }
  const parts: Array<string> = document.location.host.split('.');

  if (document.location.host.search(/co.uk/i) !== -1) {
    domain =
      parts[parts.length - 3] +
      '.' +
      parts[parts.length - 2] +
      '.' +
      parts[parts.length - 1];
  } else {
    domain = parts[parts.length - 2] + '.' + parts[parts.length - 1];
  }

  return domain.toLowerCase();
}

export const useCookieConsent = (config?: useCookieConsentConfig) => {
  const {
    levelDefault = ConsentLevel.Necessary,
    levelMax = ConsentLevel.Marketing,
    logApiEndpoint = '/api/cclog/',
  } = config || {};

  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME]);

  function init(): void {
    const currentLevel = getConsentLevel();
    const currentType = getConsentType();

    // Exit if already at max consent level or rejected (active consent below level 2)
    const hasReachedMaxLevel = currentLevel >= levelMax;
    const hasActiveDecision =
      currentLevel <= ConsentLevel.Functional &&
      currentType === ConsentType.Active;
    if (hasReachedMaxLevel || hasActiveDecision) {
      return;
    }

    // Set initial consent level for first visits
    if (currentLevel < levelDefault) {
      setConsent(levelDefault, ConsentType.Passive, {});
    }
  }

  function getCookiePartByIndex(index: number): number {
    const arrCookieParts = (cookies[CONSENT_COOKIE_NAME] || '').split('.');
    const part =
      arrCookieParts.length >= index + 1 ? arrCookieParts[index] : '';
    const number = parseInt(part);
    return isNaN(number) ? -1 : number;
  }

  function getConsentLevel(): ConsentLevel {
    const currentLevel = getCookiePartByIndex(0);
    // Return the current level but maximum the max level allowed
    return Math.min(currentLevel, levelMax);
  }

  function getConsentType(): ConsentType {
    const currentType = getCookiePartByIndex(1);
    if (currentType === 1) {
      return ConsentType.Active;
    }
    return ConsentType.Passive;
  }

  function setConsent(
    level: ConsentLevel,
    type: ConsentType,
    version: ConsentVersion
  ) {
    const oldLevel = getConsentLevel();
    const oldType = getConsentType();

    // Skip if nothing has changed
    if (level === oldLevel && type === oldType) {
      return;
    }

    // Gather data for cookie
    const tsShort = Math.round(Date.now() / 1000),
      tsLong =
        window.performance && window.performance.now
          ? window.performance.now()
          : Date.now(),
      ua = navigator.userAgent,
      loc = window.location.href,
      info = window.btoa ? window.btoa(loc + ' ' + ua) : '',
      id = md5(
        level.toString() +
          type.toString() +
          tsLong.toString() +
          version.content +
          version.layout +
          info
      ).toString(),
      data: ConsentData = {
        level,
        type,
        time: tsShort,
        version,
        id,
        info,
      };

    // Set cookie consent data
    setConsentCookie(data);

    // Log data if active consent
    if (type === ConsentType.Active) {
      log(data);
    }

    // Delete cookies if new level is lower than old level
    if (level < oldLevel) {
      deleteCookies();
    }
  }

  function log(data: ConsentData): void {
    // Check logApiEndpoint from config. Skip if empty.
    if (!logApiEndpoint) {
      return;
    }

    fetch(logApiEndpoint + data.id, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  function setConsentCookie(data: ConsentData): void {
    // Set expiry time
    const expires = new Date();
    expires.setTime(
      expires.getTime() +
        (data.level > ConsentLevel.Functional
          ? COOKIE_LONG_LIVE_DAYS
          : COOKIE_SHORT_LIVE_DAYS) *
          DAY_IN_MILLISECONDS
    );

    // Set cookie value
    const cookieValue =
      data.level +
      '.' +
      data.type +
      '.' +
      data.time +
      (data.type === 1 ? '.' + data.id : '');

    setCookie(CONSENT_COOKIE_NAME, cookieValue, {
      domain: getDomain(),
      expires,
      path: '/',
      sameSite: 'lax',
    });
  }

  function deleteCookies(): void {
    if (typeof localStorage !== 'undefined') localStorage.clear();
    if (typeof sessionStorage !== 'undefined') sessionStorage.clear();
    if (typeof document === 'undefined') return;

    const cookies = document.cookie.split(';');
    for (const c of cookies) {
      const cookieName = c.split('=')[0];
      // If the cookie name contains our consent cookie name, then skip to next
      if (cookieName.indexOf(CONSENT_COOKIE_NAME) > -1) {
        continue;
      }
      // All other cookies are deleted now
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    }
  }

  function isAllowed(level: ConsentLevel): boolean {
    const currentLevel = getConsentLevel();
    return currentLevel >= level;
  }

  return {
    init,
    getConsentLevel,
    getConsentType,
    setConsent,
    deleteCookies,
    isAllowed,
  };
};
