import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { BoxShadow, Color, Gradient } from '../../Foundation';
import { Icons, IconWrapper } from '../../Icons';
import { mediaQuery } from '../../Layout';
import { AnimatedButton } from '../Button';
import { useHandleModalOpen } from './utils';

export interface ModalProps {
  handleClose?: (
    e:
      | KeyboardEvent
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => void;
  ariaCloseLabel?: string;
  open: boolean;
  fullscreen?: boolean;
  backgroundColor?: string;
  overlayColor?: string;
  top?: number;
  zIndex?: number;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  handleClose,
  open,
  ariaCloseLabel,
  fullscreen = false,
  backgroundColor,
  overlayColor,
  top = 0,
  zIndex = 999999,
}) => {
  React.useEffect(() => {
    document.body.style.overflowY = open ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [open]);

  const modalRef = React.useRef<HTMLDivElement>(null);
  useHandleModalOpen(modalRef, open, handleClose);
  if (!open) {
    return null;
  }
  return (
    <ModalContainer zIndex={zIndex} top={top} ref={modalRef}>
      <ModalBackdrop
        top={top}
        onClick={handleClose}
        overlayColor={overlayColor}
      />
      <StyledModal
        backgroundColor={backgroundColor}
        fullscreen={fullscreen}
        open={open}
      >
        {handleClose && (
          <CloseButtonContainer>
            <AnimatedButton
              aria-label={ariaCloseLabel}
              onClick={(e) => handleClose(e)}
              size="44px"
              role="button"
            >
              <IconWrapper width={'20px'}>
                <Icons.Close />
              </IconWrapper>
            </AnimatedButton>
          </CloseButtonContainer>
        )}
        <section>{children}</section>
      </StyledModal>
    </ModalContainer>
  );
};

type ModalContainerProps = {
  top: number;
  zIndex: number;
};
const ModalContainer = styled.div<ModalContainerProps>`
  position: fixed;
  top: ${({ top }) => top + 'px'};
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: ${({ top }) => `calc(100vh - ${top}px)`};
  ${css`
    animation: ${keyframes`
      from {opacity: 0;}
      to {opacity: 1;}
    `} 0.5s ease-in-out;
  `}
`;
type StyledModalProps = {
  fullscreen: boolean;
  open: boolean;
  backgroundColor?: string;
};
const StyledModal = styled.dialog<StyledModalProps>`
  ${(props) =>
    props.fullscreen
      ? css`
          width: 100%;
          height: 100%;
          padding: 0;
          overflow: auto;
          ${CloseButtonContainer} {
            top: 30px;
            right: 30px;
          }
          background-color: ${props.backgroundColor || Color.white99};
        `
      : css`
          position: relative;
          z-index: 1;
          min-width: 280px;
          max-width: 95vw;
          max-height: 95vh;
          border-radius: 5px;
          box-shadow: ${BoxShadow.modal};
          background-color: ${props.backgroundColor || Color.rdBrightWhite};
          padding: 16px;
          ${mediaQuery.sm} {
            padding: 24px;
          }
        `}
  ${(props) =>
    props.fullscreen &&
    props.open &&
    css`
      animation: ${keyframes`
        from {transform: translateY(-100vh)}
        to {transform: translateY(0)}
      `} 0.5s ease-in-out;
    `}
  border: none;
`;

const CloseButtonContainer = styled.nav`
  ${mediaQuery.lg} {
    position: absolute;
    right: -22px;
    top: -22px;
    width: 44px;
    height: 44px;
  }
  ${mediaQuery.lgDown} {
    button {
      width: auto;
      height: auto;
      box-shadow: none;
      padding: 10px;
      &:before {
        content: none;
      }
      &:hover,
      &:focus {
        svg path {
          fill: ${Color.rdPureBlack};
        }
      }
      &:focus:focus-visible {
        outline: auto;
      }
    }
  }
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
`;

type ModalBackdropProps = {
  overlayColor?: string;
  top: number;
};
const ModalBackdrop = styled.div<ModalBackdropProps>`
  position: fixed;
  opacity: 0.8;
  background: ${(props) =>
    props.overlayColor ? props.overlayColor : Gradient.modal};
  z-index: -1;
  height: ${({ top }) => `calc(100vh - ${top}px)`};
  width: 100%;
  cursor: pointer;
`;
