import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import NumberFormat from 'react-number-format';
import type { FormatInputValueFunction } from 'react-number-format';
import styled, { css } from 'styled-components';
import { RddkTheme } from '../../Foundation';
import { Color } from '../../Foundation/Color';
import { Box } from '../../Layout/FlexBox/Box';
import { Flex } from '../../Layout/FlexBox/Flex';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { CleanIcon } from './components/CleanIcon';
import { ConfirmIcon } from './components/ConfirmIcon';
import { EmailIcon } from './components/EmailIcon';
import { Input } from './components/Input';
import { PasswordIcon } from './components/PasswordIcon';

type Icon = 'email' | 'password' | 'checkmark';
type Layout = 'grey' | 'white';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  variant?: 'numeric';
  onReset?: () => void;
  type?: InputTypes;
  errorMessage?: string;
  layout?: Layout;
  icon?: Icon;
  leadingElement?: React.ReactNode;
  trailingElement?: React.ReactNode;
  placeholder?: undefined;
  // Types for number input from react-number-format
  // More info: https://www.npmjs.com/package/react-number-format
  // Adding these types to not create duplicated component just because of types
  thousandSeparator?: boolean | string;
  decimalSeparator?: boolean | string;
  thousandsGroupStyle?: 'thousand' | 'lakh' | 'wan';
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  displayType?: 'input' | 'text';
  prefix?: string;
  suffix?: string;
  format?: string | FormatInputValueFunction;
  removeFormatting?: (formattedValue: string) => string;
  mask?: string | string[];
  allowNegative?: boolean;
  allowEmptyFormatting?: boolean;
  allowLeadingZeros?: boolean;
  allowedDecimalSeparators?: Array<string>;
  focusOnRender?: boolean;
}

export function InputField({
  label = '',
  onReset,
  errorMessage,
  layout = 'grey',
  icon,
  onChange,
  onFocus,
  onBlur,
  disabled,
  type,
  leadingElement,
  trailingElement,
  required,
  value,
  variant,
  defaultValue,
  id,
  focusOnRender = false,
  ...rest
}: InputProps): JSX.Element {
  const [haveValue, setHaveValue] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isRevisiting, setIsRevisiting] = useState(false);
  const [isClearButtonRequired, setIsClearButtonRequired] = useState(false);
  const [waitingForRevisit, setWaitingForRevisit] = useState(false);
  const [numericRef, setNumericRef] = useState<HTMLInputElement>();
  const inputRef = useRef<HTMLInputElement>(null);

  const checkInputValue = useCallback(() => {
    setHaveValue(!!inputRef.current?.value || !!numericRef?.value);
  }, [inputRef, numericRef]);

  useEffect(() => {
    checkInputValue();
    setHaveValue(!!defaultValue || !!value);
  }, [defaultValue, value, checkInputValue]);

  useEffect(() => {
    if (focusOnRender && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusOnRender]);

  const checkRevisiting = (value: string, focused: boolean) => {
    const show = !!(focused && value && waitingForRevisit && onReset);
    setIsRevisiting(show);
    if (show) {
      setIsClearButtonRequired(show);
    } else {
      setTimeout(() => {
        setIsClearButtonRequired(false);
      }, 300);
    }
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    checkRevisiting(event.target.value, true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (event.target.value) {
      setWaitingForRevisit(true);
    }
    checkRevisiting(event.target.value, false);
    checkInputValue();
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleInputReset = () => {
    setHaveValue(false);
    if (onReset) {
      onReset();
    }
  };

  const handleNumericRefSet = (el: HTMLInputElement) => {
    setNumericRef(el);
  };

  const renderLeadingElement = useCallback(() => {
    if (icon === 'email') {
      return <EmailIcon />;
    }
    if (icon === 'password') {
      return <PasswordIcon />;
    }
    if (icon === 'checkmark') {
      return <ConfirmIcon />;
    }
    if (leadingElement) {
      return leadingElement;
    }
    return null;
  }, [icon, leadingElement]);

  return (
    <>
      <InputFieldStyled
        layout={layout}
        disabled={!!disabled}
        focused={isFocused}
      >
        {(leadingElement || icon) && (
          <Flex alignItems="center">
            {renderLeadingElement()}
            <Divider />
          </Flex>
        )}
        <Flex flex={1}>
          {label !== '' && (
            <Label
              isInvalid={!!errorMessage}
              isRequired={!!required}
              isFocused={isFocused}
              haveValue={haveValue}
              htmlFor={id}
            >
              {label}
            </Label>
          )}
          <Box
            width="100%"
            mt={(() => {
              if (label === '') return '9px';
              return isFocused || haveValue ? '18px' : '9px';
            })()}
          >
            {variant === 'numeric' ? (
              <NumberFormat
                label={label}
                getInputRef={handleNumericRefSet}
                customInput={InputStyled}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                disabled={disabled}
                type={type as 'text'}
                required={required}
                value={value as string | number | undefined}
                defaultValue={defaultValue as string | number | undefined}
                isFocused={isFocused}
                haveValue={haveValue}
                id={id}
                {...rest}
              />
            ) : (
              <InputStyled
                label={label}
                ref={inputRef}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                disabled={disabled}
                type={type}
                required={required}
                value={value}
                isFocused={isFocused}
                haveValue={haveValue}
                id={id}
                {...rest}
              />
            )}
          </Box>
        </Flex>
        {isClearButtonRequired && (
          <Flex alignItems="center">
            <CleanInputButton onClick={handleInputReset} visible={isRevisiting}>
              <CleanIcon />
            </CleanInputButton>
          </Flex>
        )}
        {trailingElement && (
          <Flex alignItems="center">
            <Divider />
            {trailingElement}
          </Flex>
        )}
      </InputFieldStyled>
      {errorMessage && (
        <Box mt={1} ml={20}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
    </>
  );
}

const InputStyled = styled(Input)<{
  haveValue: boolean;
  isFocused: boolean;
  label: string;
}>`
  ${({ haveValue, isFocused, label }) => {
    if (label === '')
      return `height: 56px;
    position: absolute;
    top: 0;
    left: 20px;
    right: 0;`;

    return haveValue || isFocused
      ? ''
      : `height: 56px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;`;
  }}
`;

const InputFieldStyled = styled.div<{
  layout?: Layout;
  disabled?: boolean;
  focused?: boolean;
}>`
  display: flex;
  position: relative;
  padding: 9px 20px;
  height: 56px;
  width: 100%;
  border-radius: 5px;
  background-color: ${({ focused }) => (focused ? '#FFFFFF' : '#f4f4f4')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  box-shadow: ${({ focused }) =>
    focused ? '0 2px 6px 0 rgba(0, 0, 0, 0.15)' : ''};
  transition: all 0.3s;

  ${({ layout }) =>
    layout === 'white' &&
    css`
      background: #fff;
      border: 1px solid #9e9e9e;
    `}
`;

const Label = styled.label<{
  isInvalid: boolean;
  isRequired: boolean;
  isFocused: boolean;
  haveValue: boolean;
}>`
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  line-height: 14px;
  top: 18px;
  color: #6b6b6b;
  letter-spacing: 0.3px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: ${RddkTheme.fonts.sans};
  font-style: italic;
  ${({ isFocused, haveValue }) =>
    isFocused || haveValue
      ? `
  top: 9px;
  line-height: 14px;
  font-size: 11px;`
      : ''}
  color: ${({ isInvalid }) => (isInvalid ? Color.red40 : Color.grey70)};
  ${({ isRequired }) =>
    isRequired
      ? `:after {
    content:" *";
    color: ${Color.red40};
  }`
      : ''}
`;

const CleanInputButton = styled.button<{ visible?: boolean }>`
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  cursor: ${({ visible }) => (visible ? 'pointer' : 'default')};
  border: none;
  background-color: ${Color.grey30};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  path {
    fill: #ffffff;
  }
`;

const Divider = styled.div`
  height: 22px;
  width: 3px;
  margin: 0 10px;
  background-color: ${Color.grey20};
  display: inline-block;
  position: relative;
`;

type InputTypes = 'text' | 'email' | 'password' | 'search' | 'url' | 'tel';
