import * as React from 'react';
import styled from 'styled-components';
import { Color, Typography } from '../../../Foundation';
import { Icons, IconWrapper } from '../../../Icons';
import { mediaQuery } from '../../../Layout';
import { Button } from '../../Button/Button';
import { ButtonGroup } from '../../ButtonGroup/ButtonGroup';

export interface CookieConsentHandlerInterface {
  /**
   * Boolean whether to show or hide the banner.
   */
  show?: boolean;
  /**
   * Current consent level stored in consent cookie
   */
  consentLevel?: number;
  /**
   * Callback for when banner is initialized
   */
  onInit?: () => void;
  /**
   * Callback for when banner is reopened
   */
  onReopen?: () => void;
  /**
   * Callback for when consent level is submitted by button click.
   */
  onSubmit?: (level: number) => void;
  /**
   * Callback for when closing the banner.
   */
  onClose?: () => void;
}

export type CookieConsentBannerProps = {
  /**
   * The handler for managing cookie consent events. Required.
   */
  handler: CookieConsentHandlerInterface;
  /**
   * Show the close button in top right corner.
   * Default: false
   */
  showCloseButton?: boolean;
  /**
   * Top title/heading in the banner.
   */
  title?: string;
  /**
   * Text below title.
   */
  description?: React.ReactNode;
  /**
   * Text on secondary button for allowing necessary.
   */
  buttonAllowNecessaryText?: string;
  /**
   * Text on secondary button for allowing selected.
   */
  buttonAllowSelectedText?: string;
  /**
   * Text on primary button for allowing all.
   */
  buttonAllowAllText?: string;
  /**
   * The levels of consents. There should at least be 1 level. There is no maximum amount on levels.
   * The consent level matches the index number of the level in the array.
   */
  levels?: {
    name?: string;
    description?: string;
  }[];
};

/**
 * Constants
 */
const BOX_MARGIN = 60;

/**
 * Component for showing the cookie consent banner.
 */
export const CookieConsentBanner: React.FC<CookieConsentBannerProps> = (
  props
) => {
  const {
    handler,
    showCloseButton = false,
    title = '',
    description = '',
    buttonAllowNecessaryText = '',
    buttonAllowSelectedText = '',
    buttonAllowAllText = '',
    levels = [],
  } = props;

  const currentConsentLevel = Math.max(handler.consentLevel || 0, 0);
  const [level, setLevel] = React.useState(currentConsentLevel);

  const prefixer = (i: number) => 'cookielevel-' + i;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const intValue = parseInt(event.target.value) || 0;
    const newCheckedLevel = event.target.checked ? intValue : intValue - 1;
    setLevel(newCheckedLevel);
  };

  const onAllowSelected = () => {
    if (handler.onSubmit) {
      handler.onSubmit(level);
    }
  };

  const onAllowAll = () => {
    if (handler.onSubmit) {
      const maxLevel = levels.length - 1;
      setLevel(maxLevel);
      handler.onSubmit(maxLevel);
    }
  };

  const onClose = () => {
    if (handler.onClose) {
      handler.onClose();
    }
  };

  React.useEffect(() => {
    if (handler.onInit) {
      handler.onInit();
    }
    // TODO: Adding handler to dependency array will make
    // cookie banner go into an infinite rendering loop on
    // localhost, making it unable remove it from screen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!handler.show) {
    return null;
  }

  return (
    <Overlay>
      <Box tabIndex={0} aria-live="assertive" role="alertdialog">
        {showCloseButton && (
          <CloseButton tabIndex={0} onClick={onClose} aria-label="Close">
            <IconWrapper width="16px" height="16px">
              <Icons.Close />
            </IconWrapper>
          </CloseButton>
        )}

        <Content>
          <Top>
            <IconWrapper width="150px" height="40px">
              <Icons.Logo />
            </IconWrapper>
          </Top>

          <ScrollArea>
            <Title>
              <Typography type="h6">{title}</Typography>
            </Title>

            <Description type="manchetSmall">{description}</Description>

            <Categories>
              {levels.map((l, i) => (
                <CategoryRow key={prefixer(i)}>
                  <Checkbox
                    id={prefixer(i)}
                    value={i}
                    checked={level >= i}
                    onChange={handleCheckboxChange}
                    disabled={i === 0}
                  />
                  <Label htmlFor={prefixer(i)}>
                    <LabelTitle>{l.name}</LabelTitle>
                    <LabelSubtitle type="image">{l.description}</LabelSubtitle>
                  </Label>
                </CategoryRow>
              ))}
            </Categories>
          </ScrollArea>

          <Buttons>
            <Button variant="secondary" onClick={onAllowSelected}>
              {level > 0 ? buttonAllowSelectedText : buttonAllowNecessaryText}
            </Button>
            <Button onClick={onAllowAll}>{buttonAllowAllText}</Button>
          </Buttons>
        </Content>
      </Box>
    </Overlay>
  );
};

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(237, 231, 236, 0.9) 0%,
    rgba(255, 255, 255, 0.8) 100%,
    transparent 100%
  );
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 100000;
`;

const Box = styled.div`
  width: calc(100% - ${BOX_MARGIN}px);
  max-width: 1200px;
  position: absolute;
  top: 50vh;
  left: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  outline: none;
`;

const CloseButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);

  ${mediaQuery.sm} {
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
  }
`;

const Content = styled.div`
  max-height: calc(100vh - ${BOX_MARGIN}px);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 5px 17px 14px 13px rgba(140, 140, 140, 0.07);

  ${mediaQuery.sm} {
    padding: 40px 50px;
  }
  ${mediaQuery.lg} {
    padding: 70px 90px 50px;
  }
`;

const Top = styled.div`
  position: relative;
  padding-bottom: 5px;

  ::after {
    content: '';
    display: block;
    position: absolute;
    height: 20px;
    z-index: 10;
    bottom: -20px;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
`;

const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
`;

const Description = styled(Typography)`
  font-size: 14px;
  line-height: 22px;

  a {
    color: ${Color.rdRed};
    font-weight: 600;
    text-decoration: none;

    :hover {
      text-decoration: none;
    }
  }
`;

const ScrollArea = styled.div`
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Categories = styled.div`
  margin: 30px 0;
`;

const CategoryRow = styled.div`
  position: relative;
  max-width: 800px;
  margin-bottom: 25px;
`;

const Label = styled.label`
  display: inline-block;
  padding-left: 27px;
  cursor: pointer;
`;

const LabelTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
`;

const LabelSubtitle = styled(Typography)`
  font-size: 12px;
`;

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  top: 2px;
  cursor: pointer;
`;

const Buttons = styled(ButtonGroup)`
  position: relative;
  padding-top: 5px;

  ::before {
    content: '';
    display: block;
    position: absolute;
    height: 20px;
    z-index: 10;
    top: -20px;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  ${mediaQuery.smDown} {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column-reverse;

    && > button {
      margin-right: 0;
      display: flex;
      justify-content: center;
    }
  }
`;
